import React from "react";
import Layout from "layout";
import Hero from "common/hero";
import { Container, Row, Col } from "react-grid-system";
import { TypeA, TypeB } from "common/callouts";
import useDeviceSize from "libs/hooks/useDeviceSize";
import Seo from "common/seo";
import heroImageRef from "images/home/hero-home-image.webp";
import img1 from "images/home/cout-new-left.webp";
import img2 from "images/home/cout-new-center.webp";
import img3 from "images/callouts/sunproducts2x.webp";
import img4 from "images/home/immersive-hydration.webp";
import img5 from "images/home/legacy-feature.webp";
import img6 from "images/home/resource-feature.webp";
import img7 from "images/home/cout-center.webp";
import img8 from "images/home/samples-cta-callout-above-footer.webp";
import "./index.scss";

function HomePage() {
  const deviceSizes = useDeviceSize();

  return (
    <Layout pageClass="home" hero={<MyHero />}>
      <Seo pageTitle="Homepage" />
      <section className="inner-centered-container therapeutic-formulation-wrapper my-75 ">
        <h1 className="text-center">
          Therapeutic formulations backed by science
        </h1>
        <Container fluid>
          <Row>
            <Col sm={12} md={4} className={deviceSizes?.smUp ? "no-pl" : ""}>
              <TypeA
                imgRef={img1}
                imgAlt="Explore Body Care"
                caption="Clinically proven therapeutic solutions for body skin care"
                buttonText="Explore Body Care"
                buttonUrl="/body"
                imgStyles={{ height: 110 }}
              />
            </Col>
            <Col sm={12} md={4}>
              <TypeA
                imgRef={img2}
                imgAlt="Immersive Hydration product line with a red new flag"
                caption="Daily face routine with
                multi-&#x2060;weight hyaluronic acid"
                buttonText="Explore Face Care"
                buttonUrl="/face/eucerinfaceproducts"
                imgStyles={{ height: 155 }}
              />
            </Col>
            <Col sm={12} md={4} className={deviceSizes?.smUp ? "no-pr" : ""}>
              <TypeA
                imgRef={img3}
                imgAlt="Explore Sun Care"
                caption="Sun protection for all skin types and tones"
                buttonText="Explore Sun Care"
                buttonUrl="/sun"
                imgStyles={{ width: 236 }}
              />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="fluid-section gray-bg-grad border-top">
        <div className="inner-centered-container">
          <Row>
            <Col md={12} lg={6} className={deviceSizes?.smUp ? "no-pr" : ""}>
              <div className="cout-products-orange-bg">
                <img
                  src={img4}
                  alt="Immersive Hydration product line with blue and white floating molecules"
                  className="home-orange-cout-img"
                />
              </div>
            </Col>
            <Col md={12} lg={6}>
              <TypeB
                head1={`Eucerin<sup>&reg;</sup> Face,
                featuring Immersive
                Hydration technology`}
                head2={`Lightweight formulas with multi-weight
                hyaluronic Acid (ha) aNd Antioxidant Complexes`}
                buttonText="DISCOVER Eucerin Face"
                buttonUrl="/face"
                className="center-v cout-right-condensed"
              >
                Science meets elegance in this new day/night face routine
                designed to intensely hydrate and help reduce roughness for a
                visibly smoother and more radiant appearance.
              </TypeB>
            </Col>
          </Row>
        </div>
      </section>
      <section className="fluid-section gray-bg-grad border-top">
        <div className="inner-centered-container">
          <Row>
            <Col sm={12} md={12} lg={6} order={{ xs: 2, lg: 1 }}>
              <TypeB
                head1={`Experience our legacy<br class='show-desktop' /> of innovation`}
                head2={`FROM SKIN CARE TO SKIN REPAIR`}
                buttonText="About our EXPERTISE"
                buttonUrl="/aboutus"
                className="center-v cout-left-condensed"
              >
                Eucerin<sup>&reg;</sup> and Aquaphor<sup>&reg;</sup> deliver a
                legacy of innovation to soothe and restore{" "}
                <nobr>healthy-looking</nobr> skin.
              </TypeB>
            </Col>
            <Col
              sm={12}
              md={12}
              lg={6}
              order={{ xs: 1, lg: 2 }}
              className={deviceSizes?.smUp ? "no-pl" : ""}
            >
              <div>
                <img src={img5} alt="Products" className="img-legacy-feature" />
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <section className="fluid-section gray-bg-grad border-top">
        <div className="inner-centered-container">
          <Row>
            <Col sm={12} md={5}>
              <div>
                <img src={img6} alt="Products" />
              </div>
            </Col>
            <Col sm={12} md={7}>
              <TypeB
                head1={`Resources to support your<br class='show-desktop' /> recommendation`}
                buttonText="Browse Resource Suite"
                buttonUrl="/resources"
                className="center-v cout-right-condensed"
              >
                Find the right educational resource for your practice and
                patients in the growing suite of Eucerin and Aquaphor resources.
              </TypeB>
            </Col>
          </Row>
        </div>
      </section>
      <section className="callouts-container gradient-bg blue-grad">
        <div className="inner-centered-container">
          <Row>
            <Col sm={12} md={6} className={deviceSizes?.smUp ? "no-pl" : ""}>
              <TypeA
                imgRef={img7}
                imgAlt="Browse resources"
                caption="Gentle therapeutic care<br class='show-desktop' />
                for babies’ skin"
                buttonText="EXPLORE BABY CARE"
                buttonUrl="/baby"
                imgStyles={{ height: 185 }}
              />
            </Col>

            <Col sm={12} md={6} className={deviceSizes?.smUp ? "no-pr" : ""}>
              <TypeA
                imgRef={img8}
                imgAlt="Request Samples"
                caption="Have skin care products on hand<br class='show-desktop' /> for patients to take home"
                buttonText="Request Samples"
                isExternalLink={true}
                buttonUrl="https://bdf.hcpsampling.com"
                imgStyles={{ height: 185 }}
                targetOpen="_blank"
              />
            </Col>
          </Row>
        </div>
      </section>
    </Layout>
  );
}

export default HomePage;

const MyHero = () => <Hero className="home-hero" bgImagePath={heroImageRef} altText="Immersive Hydration product line with blue and white floating molecules"/>;
